var Common = require('./common')
var homeHomeroom = {}

homeHomeroom.init = function(){
  Common.chosen();
  Common.sizeContentCall();

  $('.owl-carousel').owlCarousel({
    items:1,
    loop:true,
    margin:0,
    dots:false,
    autoplay:true,
    nav:true,
    navText: [ '', '' ],
    autoplayTimeout:5000,
    autoplayHoverPause:true
  });
    
  $(window).load(function(){
    var windowWidth = $(window).width();
    if (windowWidth > 767) {
      $('.fixheights .anc-data').each(function() {
        if ($(this).height() > 300) {
          $(this).addClass("fix-heights");
          $(this).next(".condense_control_more").show();
        }else{
          $(this).removeClass("fix-heights");
          $(this).next(".condense_control_more").hide();
        }
      });
    }
  });


}

module.exports = homeHomeroom
