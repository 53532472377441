//var SignaturePad = require('js/signaturePad');
import SignaturePad from 'signature_pad';
SignaturePad.prototype.cropedSignatureImage = function() {
  var canvas = this._ctx.canvas;
  // First duplicate the canvas to not alter the original
  var croppedCanvas = document.createElement('canvas'),
      croppedCtx    = croppedCanvas.getContext('2d');

      croppedCanvas.width  = canvas.width;
      croppedCanvas.height = canvas.height;
      croppedCtx.drawImage(canvas, 0, 0);

  // Next do the actual cropping
  var w         = croppedCanvas.width,
      h         = croppedCanvas.height,
      pix       = {x:[], y:[]},
      imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height),
      x, y, index;

  for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
          index = (y * w + x) * 4;
          if (imageData.data[index+3] > 0) {
              pix.x.push(x);
              pix.y.push(y);

          }
      }
  }
  pix.x.sort(function(a,b){return a-b});
  pix.y.sort(function(a,b){return a-b});
  var n = pix.x.length-1;

  w = pix.x[n] - pix.x[0];
  h = pix.y[n] - pix.y[0];
  var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

  croppedCanvas.width = w;
  croppedCanvas.height = h;
  croppedCtx.putImageData(cut, 0, 0);

  return croppedCanvas.toDataURL();
};
var DigitalSignature = function(div, valElem) {
  return {
    init: function() {
      this.draw(div);
    },
    styles: function() {
      var styleStr = `<style>
        .wrapper {
          position: relative;
          width: 400px;
          height: 200px;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          margin: 10px 0;
        }
        .signature-pad {
          background: white;
          width:400px;
          height:200px;
          border: 1px solid lightgray;
        }
        .btn-container {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      </style>
      `;
      return styleStr;
    },
    draw: function(div) {
      var str =`${this.styles()}
      <h4>
        Signature
      </h4>
      <div id="sign-box">
        <div class="wrapper">
          <canvas id="signature-pad" class="signature-pad" width=400 height=200></canvas>
          <div class="btn-container">
            <input type="button" value="Reset" id="clear" />
          </div>
        </div>
      </div>`
      div.html(str);
      this.bindEvents();
    },
    bindEvents: function() {
      var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)',
        onEnd: function (event) {
          if(!signaturePad.isEmpty()) {
            var data = signaturePad.cropedSignatureImage();
            valElem.val(data);
          }
        }
      });

      var cancelButton = document.getElementById('clear');
      cancelButton.addEventListener('click', function (event) {
        signaturePad.clear();
        valElem.val('');
      });
    }
  }
};
export default DigitalSignature;
