var approvals = {}

approvals.init = function(){

  $(document).ready(function(){
    approvals.ajaxAction();
    approvals.filter();
  });

}

approvals.filter = function(){
  $(".approval_filter").click(function(e) {
    var val = $(this).attr('id');
    if(val == "all_group"){
      $(".common_class").show();
    }
    else{
      $(".common_class").hide();
      $("." + val).show();
    }
    $(".visible_item_text").text($(this).text());
  });
}

approvals.ajaxAction = function(){
  $('a.ajax-action').click(function(e) {
    e.preventDefault();
    var parent = $(this).closest('.hover-actions');
    var parent_cls = $(this).attr('data-parent_cls');
    $(this).text($(this).attr('data-loading'));
    $.ajax({
      url: $(this).attr('data-url'),
      method: 'GET',
      success: function() {
        parent.slideUp(300,function() {
          var top_class = parent.closest('.common_class');
          parent.next('hr.dashedborder').remove();
          parent.remove();
          if($('.' + parent_cls + ' .hover-actions').length == 0) {
            top_class.remove();
          }
        });
      }
    });
  });
}

module.exports = approvals