window.promptForm = function(form) {

 form.data("formInProgress", false);
  form.change(function(){
    form.data("formInProgress", true);
  });

  form.submit(function() {
    form.data("formInProgress", false);
    return true; //allow form to submit
  });

  form.on('input', ("div[contenteditable='true']") , function() {
    form.data("formInProgress", true);
  })

  function isFormInProgress() {
    const formInProgress = form.data("formInProgress");
    if(formInProgress) {
      return 'You have unsaved changes on this page. If you wish to leave this page and discard your changes than click "OK" OR click "Cancel" to stay on this page';
    }
  }

  $('body').on('click', 'a', function(e){
    if(isFormInProgress() && $(this).attr('href') && $(this).attr('href').indexOf('/') > -1 && $(this).attr('target') !== '_blank') {
      if(confirm('You have unsaved changes on this page. If you wish to leave this page and discard your changes than click "OK" OR click "Cancel" to stay on this page')) {
        //let go user to new page...
        form.data("formInProgress", false);
      } else {
        e.preventDefault();
        return false;
      }
    }
  })

  window.onbeforeunload = isFormInProgress;

}
