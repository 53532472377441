var Cocoon = require('./cocoon')

var PeopleEdit = {}

function setUrlForIframe() {
  var url = $("#preview_link")[0].src.split('?')[0];
  var name = $("input[type=text][name='person[first_name]").val()
  var hp = $("input[type=password][name='person[new_password]").val() === "" ? '' : 'true'
  url = `${url}?name=${name}&hp=${hp}`
  $("#preview_link").attr('src', url)
}

PeopleEdit.init = function () {
  Cocoon.init()

  $('a.update-by-ajax').click(function (e) {
    e.preventDefault();
    var id = $(this).attr('id')
    var parentObj = $(this).closest('.hover-actions')
    var border = $(this).closest('.hover-actions').next()
    $.ajax({
      //Using existing approval controller
      url: "/approvals/" + id + "-person/approve",
      method: 'GET',
      success: function () {
        alert('Approved')
        location.reload()
      }
    });
  });

  $('.person_type input').on('click', function () {
    var personNow = $('#person-now').data('person-now');
    var personChange = $(this).attr('value');
    if (personNow === personChange) {
      $('#msg-person-change').css('visibility', 'hidden')
    } else {
      $('#msg-person-change').css('visibility', 'visible')
      $('#person-change-to').text(personChange)
    }
    if (!(personChange === 'Student')) {
      if ($('#person-tabs .student-section').hasClass('active')) {
        //$('#person-tabs li:first-child a').click()
        $('#person-tabs li.firsttab a').click()
      }
    }
  })

  $("#view-family-contributions a").on('click', function () {
    $("#view-family-contributions").html('Loading...');
    $("#view-family-contributions").load("contribution");
  })

  $("#email_password a").on('click', function () {
    $("#email_password").html('<div class="col-md-12"><p>Loading...</p></div>');
    $("#email_password").load('/people/' + $(this).attr("data-personid") + '/email_password');
  })

  $("#emergency_info a").on('click', function () {
    $("#emergency_info #field_row").toggleClass('hide', 'slow');
  })

  $('input[name="person[type]"]').click(function () {
    var kind = $('input[name="person[type]"]:checked').val();
    $(".row.student, .student-section").toggleClass("hidden", kind !== "Student");
    $(".student-not-section").toggleClass("hidden", kind === "Student");
    $(".row.parent, .parent-section").toggleClass("hidden", kind !== "Parent");
  });

  $('#person_image').change(function () {
    try {
      $('#image').html("New photo selected.  Please click 'Save' below.")
    } catch (err) { }
  });

  $('#person_email').change(function () {
    try {
      $.ajax(
        {
          type: "GET",
          url: '/people/check_email',
          dataType: "json",
          data: { email: $(this).val(), type: $('input[name="person[type]"]:checked').val() },
          success: function (data) {
            if (data.present) {
              $('#check_email_msg').html(data.msg);
              $('#check_email_div').show('slow');
            }
            else {
              $('#check_email_div').hide('slow');
            }
          },
          error: function () {
            console.log('ajax loading error... ... ');
            return false;
          }
        });
    } catch (err) { }
  });

  if (Modernizr.touch) {
    $('.datepicker').click(function () {
      $(this).blur();
    });
  } else {
    Common.datePicker();
    $('.links .add_fields').click(function () {
      setTimeout(function () {
        Common.datePicker();
      }, 500);
    })
  }

  $(function () {
    $(window).scroll(Common.stickyElement);
    Common.stickyElement();
  });

  Common.tabDrop();

  $('.person_type input').click(function () {
    Common.tabDrop();
  })

  $("input[type=radio][name='person[type]']").change(function () {
    window.onbeforeunload = null;
    $('#type_changed').val(true);
    $('form.simple_form.edit_person.edit-prompt').submit();
  });

  $("input[type=text][name='person[first_name]").change(setUrlForIframe)
  $("input[type=password][name='person[new_password]").change(setUrlForIframe)

}

PeopleEdit.initPersonNav = function (personId) {
  $('.prev_person, .next_person').hide();
  var lastSearchIds = localStorage.getItem('lastSearchIds');
  if (!lastSearchIds) {
    return;
  }
  var lastSearchIds = JSON.parse(lastSearchIds);
  var index = lastSearchIds.indexOf(personId);
  if (index === -1) {
    return;
  }
  if (index >= 0 || index < lastSearchIds.length - 1) {
    var nextItem = lastSearchIds[index + 1];
    var prevItem = lastSearchIds[index - 1];
    if (nextItem) {
      $('#next_person').val(nextItem);
      $('.next_person').attr('href', '/people/' + nextItem + '/edit');
      $('.next_person').show();
    }
    if (prevItem) {
      $('#prev_person').val(prevItem);
      $('.prev_person').attr('href', '/people/' + prevItem + '/edit');
      $('.prev_person').show();
    }
  }
}

PeopleEdit.initErrors = function () {
  $(document).ready(function () {
    const tabCounts = {};
    $(".bs-callout-danger ul li").each(function (i, item) {
      $(this).html("<a href='javascript:void(0)'>" + $(this).text() + "</a>");
      var attr = $(this).attr('element');
      var element = $("[name='person[" + attr + "]'], [name='person[" + attr + "][]']");
      var tabId = element.parents(".tab-pane").attr("id");
      tabCounts[tabId] = tabCounts[tabId] ? tabCounts[tabId] + 1 : 1;

      $(this).find('a').on('click', function () {
        var attr = $(this).parent('li').attr('element');
        var element = $("[name='person[" + attr + "]'], [name='person[" + attr + "][]']");
        var tabId = element.parents(".tab-pane").attr("id");
        tabCounts[tabId] = tabCounts[tabId] ? tabCounts[tabId] + 1 : 1;
        $(".nav-tabs").find("a[href='#" + tabId + "']").click();
        element.focus().trigger('chosen:open');
        //$('html, body').animate({scrollTop: element.offset().top-50},0);
      });

      if (i == 0) {
        var _this = this;
        setTimeout(() => {
          $(_this).find('a').click();
        })
      }
    });
    for (var tab in tabCounts) {
      $(".nav-tabs").find("a[href='#" + tab + "']").addClass('text-red');
    }
  })
}

module.exports = PeopleEdit
