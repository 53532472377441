var filePreview = {}

filePreview.init = function(){
  var previewPanelHTML = $('#enlarger');
  var thumbRill = previewPanelHTML.find('nav > .dropzone');
  var thumbItemsWidth = 112;
  var itemCallStarted = [];

  $(document).ready(function(){

    $(document).on('click', 'a.copy_url_btn', function(evt){
      evt.preventDefault()
      $(evt.target).text('Copied!')
      setTimeout(function(){ $(evt.target).text('Copy URL') }, 1000)
      $('.copy_url_box').select()
      document.execCommand("copy")
    })

    $('.dz_preview').on('click', function(){
      previewPanel(this)
    })

    $('.preview_close').on('click', function(){
      closePreviewPanel()
    })


    thumbRill.on('click', '.dz_preview' ,function(e){
      openFile(this.id);
    })

    $('.preview_controls').on('click', '.arrow', function(){
      rillNav(this);
    })

    $(window).keydown(function(event){
      if(event.which === 39 || event.which === 37){
        keyNav(event)
      }
    })

    $(window).resize(function(){
      if(previewPanelHTML.find('figure:visible').length === 1){
        figureSize();
      }
    });

  })

  function previewPanel(that){
    let thumbItems = $(that).parent().html();
    let itemID = $(that).attr('id');

    $('html').addClass('shaded');
    previewPanelHTML.addClass('visible');

    thumbRill.append(thumbItems);
    thumbRill.find( '#'+itemID ).addClass( "active" );
    loadFile(itemID);
  }

  function loadFile(itemID){
    let thumbItems =  thumbRill.find('#'+itemID).siblings().andSelf();
    let thumbRillWidth = thumbItems.length * thumbItemsWidth;
    let thumbScreenWidth = thumbRill.parent().outerWidth(true);
    let newPosition = 0;

    setupRill();
    openFile(itemID);

    if(thumbScreenWidth < thumbRillWidth && thumbRill.find('.active').offset().left > (thumbScreenWidth-80) ){
      newPosition = ( 0 - Math.ceil((thumbRill.find('.active').offset().left - (thumbScreenWidth-80))/thumbItemsWidth) * thumbItemsWidth )
      thumbRill.css('left', newPosition);
    }
  }



  function openFile(itemID){
    let itemUrl = '/attachments/'+itemID;
    thumbRill.find( '.active' ).removeClass( "active" );
    thumbRill.find( '#'+itemID ).addClass( "active" );
    let thumbItems =  thumbRill.find('#'+itemID).siblings().andSelf();
    previewPanelHTML.find('figure' ).hide();

    if( previewPanelHTML.find('figure#'+itemID).length === 1 ){
      previewPanelHTML.find('figure' ).hide();
      previewPanelHTML.find('figure#'+itemID ).show();
      figureSize();
    }else{
      if( $.inArray(itemID, itemCallStarted ) === -1 ){
        itemCallStarted.push(itemID);
        $.getJSON(itemUrl, function (data) {
          previewPanelHTML.append(data.html);
        }).done(function(){
          previewPanelHTML.find('figure').hide();
          previewPanelHTML.find('figure#'+itemID ).show();
          figureSize();
        })
      }
    }

  }


  function closePreviewPanel(){
    $('html').removeClass('shaded');
    previewPanelHTML.removeClass('visible');
    thumbRill.children().remove();
    previewPanelHTML.find('figure' ).hide();
  }

  function keyNav(event){

    let thumbItems =  thumbRill.find(':first').siblings().andSelf();
    let thumbRillWidth = thumbItems.length * thumbItemsWidth;
    let thumbScreenWidth = thumbRill.parent().outerWidth(true);
    let newPosition = thumbRill.position().left;

    if( previewPanelHTML.hasClass( "visible" ) ){
      if(event.which === 39){
        if( thumbRill.find('.active').next().length === 1 ){
          thumbRill.find('.active').next().click();
          if( thumbRill.find('.active').offset().left > thumbScreenWidth-(80) ){
            newPosition = newPosition - thumbItemsWidth;
          }
        }else{
          thumbRill.find(':first').click();
          newPosition = 0
        }
      }
      if(event.which === 37){
        if( thumbRill.find('.active').prev().length === 1 ){
          thumbRill.find('.active').prev().click();
          if( thumbRill.find('.active').offset().left < 0 ){
            newPosition = newPosition + thumbItemsWidth;
          }
        }else{
          thumbRill.find(':last').click();
          if(thumbScreenWidth < thumbRillWidth){
            newPosition = Math.floor( (thumbScreenWidth-thumbRillWidth)/thumbItemsWidth ) * thumbItemsWidth;
          }

        }
      }
      thumbRill.css('left', newPosition)
    }
  }

  function setupRill(){
    let thumbItems =  thumbRill.find(':first').siblings().andSelf();
    let thumbRillWidth = thumbItems.length * thumbItemsWidth;
    let thumbScreenWidth = thumbRill.parent().outerWidth(true);

    thumbRill.css({'width':thumbRillWidth+'px', 'left' : '0px'});

    if( thumbRillWidth < thumbScreenWidth ){
      $('.preview_controls').hide();
    }else{
       $('.preview_controls').show();
    }
  }

  function rillNav(that){
    let thumbItems =  thumbRill.find(':first').siblings().andSelf();
    let thumbRillWidth = thumbItems.length * thumbItemsWidth;
    let thumbScreenWidth = thumbRill.parent().outerWidth(true);
    let newPosition = 0


    if(  $(that).hasClass('right arrow')  ){
      newPosition = thumbRill.position().left;
      if(newPosition > (thumbScreenWidth - thumbRillWidth) ){
        newPosition = newPosition - thumbItemsWidth;
      }
    }else{
      newPosition = thumbRill.position().left;
      if( newPosition < 0 ){
        newPosition = newPosition + thumbItemsWidth;
      }
    }
    thumbRill.css('left', newPosition)
  }

  function figureSize() {
    let figure = previewPanelHTML.find('figure:visible');
    let imageHeight = $(figure).height() - ($(figure).find('.file-name').height() + $(figure).find('.file-meta').height() + 10);
    $(figure).find('.image_stage > div').css("height", imageHeight);
  }
}

module.exports = filePreview
