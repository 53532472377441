var Common = require('./common')

var ThreeSixty = {}

ThreeSixty.init = function () {

  $(function () {
    $(window).scroll(Common.stickyElement);
    Common.stickyElement();
  });

  //TODO use stickyElement() should not work in mobile
  function stickyRelocate () {
    var windowTop = $(window).scrollTop()
    var divTop = $('#sticky-desk-here').offset()?.top
    if (windowTop > divTop) {
      $('#sticky-desk').addClass('stick')
    } else {
      $('#sticky-desk').removeClass('stick')
    }
  }

  if($('#sticky-desk-here').length === 1) {
    if (!Modernizr.touch) {
      $(window).scroll(stickyRelocate)
    }
  }
  $(function () {
    $(window).scroll(stickyRelocate);
    stickyRelocate();
  });

  $('#cumulative-folder-date').datetimepicker({
    minDate: '-1970/01/01',
    timepicker: false,
    format: 'm/d/y',
    scrollInput: false,
    scrollMonth: false
  })

  $(".trimester-pan[data-trimester-pan='" + $('.trimester-dropdown a.dropdown-toggle').data('trimester-id') + "']").show()
  $('.trimester-dropdown .dropdown-menu li ').on('click', function () {
    var tid = $(this).data('trimester-id')
    var tname = $(this).data('trimester-menu')
    $('.trimester-dropdown a.dropdown-toggle').html(tname + "<span class='caret'></span>")
    $('.trimester-pan').hide()
    $(".trimester-pan[data-trimester-pan='" + tid + "']").show()
  })

  $('.student_details_tabs a').click(function (e) {
    e.preventDefault()
    $(this).tab('show')
  })

  $('.accordion-btn').on('click', function () {
    $(this).next('.accordion-panel').slideToggle()
  })

  $('.deskbar-item').on('click', function () {
    var block = $(this).attr('data-href');
    $(block).removeClass('pointing');
    $('html, body').animate({
      scrollTop: $(block).offset().top - 200
    }, 500)
    setTimeout(function () {
      $(block).addClass('pointing');
    }, 500)

  })

  jQuery('#info_popover').popover({
    trigger: 'focus hover',
    html: true,
  })

}

module.exports = ThreeSixty
