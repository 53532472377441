
var Login, init_slide_view, loginEmailValidator, move_slide, registrationValidator, validateRegistration;

Login = {};

registrationValidator = void 0;

loginEmailValidator = void 0;

init_slide_view = function (slider_view_class_name, first_screen) {
  var hideReel, slideReel, slideViewPort;
  slideViewPort = $(slider_view_class_name);
  slideViewPort.append("<div></div> <div></div>");
  slideReel = $(slider_view_class_name + "> div").eq(0);
  hideReel = $(slider_view_class_name + "> div").eq(1);
  hideReel.css({
    display: "none"
  });
  return slideReel.append($(first_screen));
};

move_slide = function (slider_view_class_name, moveto, step) {
  var hideReel, slideReel, slideViewPort;
  slideViewPort = $(slider_view_class_name);
  slideReel = $(slider_view_class_name + "> div").eq(0);
  hideReel = $(slider_view_class_name + "> div").eq(1);
  slideReel.css({
    position: "relative"
  });
  slideViewPort.css({
    overflow: "hidden"
  });
  hideReel.append(slideReel.children());
  slideReel.append($(moveto));
  $(slider_view_class_name + ' *:input[type!=hidden]:first').focus();
  if (step === "next") {
    slideReel.addClass("slide_next");
  }
  if (step === "back") {
    slideReel.addClass("slide_back");
  }
  setTimeout((function () {
    return slideReel.attr("class", "");
  }), 500);
  return $('.btn').attr("disabled", false);
};

validateRegistration = function () {
  return registrationValidator = $('form#registration_init').validate({
    rules: {
      first_name: {
        required: true
      },
      last_name: {
        required: true
      },
      email: {
        required: true,
        validEmail: true,
        minlength: 3,
        noSpace: true
      },
      password: {
        required: true,
        noSpace: true,
        minlength: 4
      },
      phone: {
        number: true,
        minlength: 10
      }
    }
  });
};

Login.init = function () {
  var preregistered_member_flag, school_id, school_type, school_type_title;
  init_slide_view(".slide_view_port", "#login1");
  school_id = $("span#school_id").text();
  school_type = $("span#school_type").text();
  preregistered_member_flag = $("span#preregistered_member_flag").text();
  school_type_title = 'School';
  if (school_type === 'Parish' || school_type === 'Department') {
    school_type_title = school_type;
  }
  validateRegistration();
  $(window).on('hashchange', function () {
    var hash;
    hash = window.location.hash;
    if (hash === '#login-home') {
      move_slide(".slide_view_port", "#login1", "back");
      return $("div.new-register a.register").show();
    }
  });
  loginEmailValidator = $('form#first_screen').validate({
    rules: {
      email_address: {
        required: true,
        noSpace: true,
        minlength: 2
      }
    }
  });
  $('form.login_fm').validate({
    rules: {
      password: {
        required: true,
        minlength: 4
      }
    }
  });
  $(".email_submit").click(function () {
    var entered_email;
    if ($('form#first_screen').valid() === false) {

    } else {
      $('.btn').attr("disabled", true);
      $("div.alert-message").text();
      entered_email = $("input#email_address").val();
      entered_email = entered_email.toLowerCase();
      return $.getJSON("/user/check?" + $.param({
        email: entered_email
      }), function (data) {
        if (data.exist) {
          $(".final_email").val(entered_email);
          window.location.hash = 'login-next';
          return move_slide(".slide_view_port", "#login3", "next");
        } else {
          loginEmailValidator.showErrors({
            email_address: "This is not a registered email address"
          });
          return $('.btn').attr("disabled", false);
        }
      });
    }
  });
  $('input#email_address').on('change', function () {
    this.value = this.value.toLowerCase();
  });
  $("#login-signup").on("click", ".forgot_pw", function () {
    $("div.alert-message").text("");
    return move_slide(".slide_view_port", "#reset_password1", "next");
  });
  $("#login3").on("click", ".validate_auth", function () {
    var entered_email, entered_password;
    console.log("login 3");
    if ($('form.login_fm').valid() === false) {
      return;
    } else {
      $('.btn').attr("disabled", true);
    }
    entered_email = $("input#email_address").val();
    entered_password = $("input#password").val();
    if (entered_email && entered_password) {
      $("form#login_sync").submit();
      return $('.btn').attr("disabled", true);
    }
  });
  $("#login3").on("click", "#showHidePassword", function () {
    $(this).find('#showHidePasswordIcon').toggleClass("glyphicon-eye-close");
    $(this).find('#showHidePasswordIcon').toggleClass("glyphicon-eye-open");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  })
  $("#login3").on("keypress", "#password", function (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      return $('#login3 .validate_auth').trigger('click');
    }
  });
  $("#login1").on("keypress", "#email_address", function (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      return $('#login1 .email_submit').trigger('click');
    }
  });
  $('form#registration_init').on("keypress", "input", function (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      return $('#registration_init .submit_registration').trigger('click');
    }
  });
  jQuery.validator.addMethod('noSpace', (function (value, element) {
    return value.indexOf(' ') < 0 && value !== '';
  }), 'Blank spaces are not allowed, please remove any space (even in the begining or end)');
  jQuery.validator.addMethod('validEmail', (function (value, element) {
    return this.optional(element) || /^(?:[0-9]|[a-z]|[\._%\+\-])+(?:@)(?:[0-9]|[a-z]|[\.\-])+$/i.test(value);
  }), 'Please enter valid email address');
  $('#registration_init #email_address').blur(function (evt) {
    var entered_email;
    entered_email = evt.target.value;
    if (!entered_email) {
      return;
    }
    entered_email = entered_email.toLowerCase();
    return $.getJSON("/user/check?" + $.param({
      email: entered_email
    }), function (data) {
      if (data.exist) {
        $('input#password_confirmation').hide();
        return $('input#password').hide();
      } else {
        $('input#password_confirmation').show();
        return $('input#password').show();
      }
    });
  });
  $('#registration_init .submit_registration').click(function (evt) {
    var entered_email, person_name;
    if ($('form#registration_init').valid() === false) {

    } else {
      evt.preventDefault();
      $('.btn').attr("disabled", true);
      person_name = $('input#first_name').val() + " " + $('input#last_name').val();
      $('input#person_name').val(person_name);
      $('input#password_confirmation').val($('input#password').val());
      entered_email = $('#email_address').val();
      entered_email = entered_email.toLowerCase();
      var searchParams = new URLSearchParams(window.location.search)
      return $.getJSON("/user/check?" + $.param({
        email: entered_email,
        sid: searchParams.get('sid'),
      }), function (data) {
        $('.btn').attr("disabled", false);
        if (data.exist && data.school_exist) {
          registrationValidator.showErrors({
            email: "This is a registered email address, please login"
          });
          return;
        }
        if (data.exist && !data.school_exist) {
          $(".final_email").val(entered_email);
          $('input#password_confirmation').remove();
          $('input#password').remove();
          $('#registration_init').submit();
        }
        if (!data.exist && !data.school_exist) {
          $(".final_email").val(entered_email);
          return $('#registration_init').submit();
        }
      });
    }
  });
  $('form#reset_pw').validate({
    rules: {
      email_address: {
        required: true,
        email: true
      }
    }
  });
  $("#reset_password1").on("keypress", ".reset_pw_email", function (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      return $('#reset_password1 .reset_password').trigger('click');
    }
  });
  $("#reset_password1").on("click", ".reset_password", function () {
    if ($('form#reset_pw').valid() === false) {
      return;
    } else {
      $('.btn').attr("disabled", true);
    }
    return $('form#reset_pw').submit();
  });
  if (window.location.hash === "#login-next") {
    $("div.alert-message").text("");
    return move_slide(".slide_view_port", "#reset_password1", "next");
  }
};

export default Login
