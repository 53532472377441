var Common = {}

Common.datePicker = function () {
  $('.datepicker').datetimepicker({
    timepicker: false,
    closeOnDateSelect: true,
    format: 'm/d/Y',
    scrollInput: false,
    scrollMonth: false
  })
};

Common.dateTimePicker = function () {
  $('.datetimepicker').datetimepicker({
    minDate: '-1970/01/01',
    format: 'm/d/Y h:i a',
    formatTime: 'h:i a',
    step: 30,
    scrollInput: false,
    scrollMonth: false,
    timepickerScrollbar: false,
  })
};

Common.chosen = function () {
  $(".chzn-select").chosen();
  $(".chzn-select-deselect").chosen({ allow_single_deselect: true });
};

Common.stickyElement = function () {
  if ($('#sticky-anchor').length === 1) {
    var window_top = $(window).scrollTop();
    var div_top = $('#sticky-anchor').offset().top;
    if (window_top > div_top) {
      $('.sidebartoggle').addClass('stick');
    } else {
      $('.sidebartoggle').removeClass('stick');
    }
  }
}

Common.colorPicker = function () {

  //localStorage.removeItem(user+'selectedColor');
  var user = $('body').data('currentpersonid');

  if (!(localStorage.getItem(user + 'selectedColor'))) {
    $.fn.colorPicker.defaults.pickerDefault = "3366CC";
  } else {
    $.fn.colorPicker.defaults.pickerDefault = localStorage.getItem(user + 'selectedColor');
  }

  $('.colorpick').on('change', function () {
    localStorage.setItem(user + 'selectedColor', $(this).val());
  })


  $.fn.colorPicker.defaults.colors = [
    '8AABC6',
    '3366CC',
    '003F97',
    '2CBBD0',
    'E9599E',
    'B557FE',
    '6ABD9E',
    '62C232',
    '579102',
    'D4C368',
    'BAB832',
    '8A8A47',
    'F5C431',
    'F79D60',
    'CF9876',
    'BD987B',
    'ED7248',
    'E93F33',
    'E6929B',
    'BB9BB9',
    'A05C5B',
    '8B243A',
    '939194',
    '000000',
  ];
  $('.colorpick').colorPicker();
};

Common.tabDrop = function () {
  $('.nav-tabs').tabdrop({
    text: 'More'
  });
  $(window).resize();
};

/* Prevent Double Submission */
/*If there are AJAX forms that should be allowed to submit multiple times
so give class to Form .js-allow-double-submission */
Common.preventDoubleSubmission = function () {
  $('form:not(.js-allow-double-submission)').submit(function (e) {
    var $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      if (typeof $form.data('valid_and_submitted') === 'undefined') {
        const valid = $form.validate ? $form.validate() : ($form.valid ? $form.valid() : true)
        if (valid) {
          $form.data('valid_and_submitted', true);
        }
        else {
          e.preventDefault();
        }
      }
      else {
        e.preventDefault();
      }

    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
    }
  });
  // Keep chainability
  return this;
}

Common.sizeContentCall = function () {
  function sizeContent() {
    // var windowWidth = $(window).width();
    // if (windowWidth > 767) {

    //   var top = $(".main-inner").offset().top;
    //   var lineTopTop = top + $(".main-inner").height();

    //   if ($(window).scrollTop() > top ) {
    //     $(".home-slide").addClass("stick");
    //     $(".home-slide").removeClass("stickbottom");

    //     var stickablePosition = $(".home-slide").offset().top + $(".home-slide").height();

    //     if ( stickablePosition > lineTopTop ) {
    //       $(".home-slide").removeClass("stick");
    //       $(".home-slide").addClass("stickbottom");
    //     };
    //   } else if ( top > $(window).scrollTop() ) {
    //     $(".home-slide").removeClass("stick");
    //   }
    // }
  }

  // $(document).ready(sizeContent);
  // $(window).resize(sizeContent);

  // $(function() {
  //   $(window).scroll(sizeContent);
  //   sizeContent();
  // });
}

Common.contentFilter = function () {
  $('.common_content_filter').on('change', function (e) {
    document.location.href = '?' + jQuery.param({ selected: $(this).val() });
  });
}

Common.setBackLink = function () {
  var url = window.location.href;
  if ($('.back-link').length === 0) {
    localStorage.setItem('lastUrl', url);
  } else {
    $('.back-link').attr('href', localStorage.getItem('lastUrl'));
  }
}
Common.addNewUrl = function () {
  if ($('#add-new-link').length === 1) {
    var addNew = $("#add-new-link");
    addNew.attr('href', $("#addnewlist ." + addNew.data().selectedLink + " a").attr("href"));
  }
}
Common.deleteAjax = function () {
  $('a.delete-by-ajax').click(function (e) {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this?")) {
      var parent = $('.' + $(this).attr('data-itemid'))
      $.ajax({
        url: $(this).attr('href'),
        method: 'DELETE',
        beforeSend: function () {
          parent.addClass('pointing');
        },
        success: function () {
          parent.slideUp(300, function () {
            parent.remove();
          });
        }
      });
    }
  });
}

Common.withDrawAjax = function () {
  window.$("body").on('click', ".reportURL", (e) => {
    e.preventDefault();
  });

  var defaultMessage = "This report is no longer available. You are seeing this message because the Sender of this Report distribution may have cancelled this report. Please contact the school with any questions.";
  $('button.withdraw-by-ajax').click(function (e) {
    let withdrawn = false;
    if ($(this).attr("data-withdrawn_reason")) {
      withdrawn = $(this).attr("data-withdrawn_reason");
    }
    const that = this;
    var msgBox = `<div id="withdrawMsgBox" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Withdraw Distribution</h4>
        </div>
        <div class="modal-body">
          ${withdrawn ? withdrawn : `
            <p>You are going to withdraw the distribution. You may specify a message.</p>
            <font style="font-size:12px; color: #999"><b>Note:</b> This message will be displayed to parents, You may leave it blank in case you wish to keep the default message visible below</font>
            <div>
              <textarea
                autofocus
                id="rejectComments"
                name="reject_comments_new"
                class="rj_comment inputbox form-control"
                placeholder="${defaultMessage}" rows="4" cols="44" style="resize: vertical;"></textarea>
            </div>`}
        </div>
        ${withdrawn ? '' : `
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" id="withDrawBtn">Withdraw</button>
        </div>`}
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->`
    $('#withdrawMsgBox').remove();
    $(document.body).append(msgBox);
    $('#withdrawMsgBox').modal();
    e.preventDefault();
    var itemId = $(this).attr('data-itemid');
    var parent = $('.item-' + itemId);
    var withdrawDistribution = function () {
      const rejectComments = $("#rejectComments").val() || defaultMessage;
      $.ajax({
        url: '/api/distribution/withdraw_report',
        method: 'POST',
        data: {
          id: itemId,
          reject_comments: rejectComments
        },
        beforeSend: function () {
          $('#withdrawMsgBox').modal('hide');
          parent.addClass('pointing');
        },
        success: function () {
          //change btn text and remove class 'withdraw-by-ajax'
          $(that).removeClass('withdraw-by-ajax').text('Withdrawn').attr('data-withdrawn_reason', 'You have withdrawn the following distribution due to this reason: ' + rejectComments);
        },
        error: function () {
          alert('There was an error while withdrawing report. Please try after sometime.');
        }
      });
    }
    $("#withDrawBtn").on('click', withdrawDistribution);
  });
}

Common.setupClassMenu = function () {
  var full_menu_cls;
  if ($(".dropdown-menu.slide-down-menu").length > 0) {
    full_menu_cls = "col" + $(".dropdown-menu.slide-down-menu").data().full_menu_size + "menu";
  }

  $("a[data-target='#classrooms_popover']").on('click', function () {
    if ($('.dropdown-menu .show_full_list').length > 0) {
      $('.dropdown-menu .memberships').show();
      $('.dropdown-menu .full_list').hide();
      $('.dropdown-menu.slide-down-menu').removeClass(full_menu_cls).addClass("col1menu");
    }
  })

  $('.dropdown-menu .show_full_list').on('click', function (event) {
    event.stopPropagation();
    $('.dropdown-menu.slide-down-menu').removeClass("col1menu").addClass(full_menu_cls);
    $('.dropdown-menu .memberships').hide();
    $('.dropdown-menu .full_list').show();
  })
}

Common.dismissGlobalAnnouncement = function () {
  $('a.dismiss').click(function () {
    console.log($(this).data('user'))
    $.ajax({
      url: "/global/last_seen",
      data: { user: $(this).data('user') },
      success: function (result) {
        $('.global-announcement').hide();
      }
    });
  });

}

Common.chosenAddplaceholder = function () {
  $('.chzn-select').each(function () {
    var chosenli = $(this).find('option').length;
    var chosenliselected = $(this).find('option:selected').length;
    if ((chosenliselected < chosenli) && (chosenliselected > 0)) {
      $(this).next('.chosen-container-multi').find('.search-field input').attr("placeholder", "Add more").addClass("addmore");
    }
  });
}

/* close global announcement */
$(document).ready(function () {
  Common.setupClassMenu();
  Common.datePicker();
  Common.dateTimePicker();
  Common.chosen();
  Common.preventDoubleSubmission();
  Common.contentFilter();
  Common.setBackLink();
  Common.addNewUrl();
  Common.deleteAjax();
  Common.withDrawAjax();
  Common.dismissGlobalAnnouncement();
  Common.chosenAddplaceholder();
  window.name = 'home';
  /* mobile sidemenu */
  $(".sidebartoggle").click(function () {
    $("#content-wrap").toggleClass("sidebarshow");
  });

  // Prevent submit by enter key in jquery chosen globally
  $("ul.chosen-choices li.search-field").on('keydown', function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  });

  $(".chosen-container-multi").click(function () {
    Common.chosenAddplaceholder();
  });

  $("a[href^='http://'],a[href^='https://']").filter(function () { return this.href.indexOf(window.location.host) == -1; }).attr("target", "_blank");

  $('a[target="_blank"]').attr('rel', 'noopener noreferrer');

  $(document).on('touchstart', '.hover-actions', function (e) {
    $(this).find('.actions-box').show();
  })

  $('[data-toggle="tooltip"]').tooltip();

});

//Go to top
$(document).on('click', '#backtop', function () {
  $("html, body").animate({ scrollTop: 0 }, "slow");
});

// Scroll
var currentP = 0;


$(window).scroll(function () {
  var headerH = $('#header').height();
  var scrollP = $(window).scrollTop();

  if ($(window).width() > 1024) {
    if (scrollP != currentP) {
      //Back to top
      if (scrollP >= headerH) {
        $('#backtop').addClass('backtopshow');
      } else {
        $('#backtop').removeClass('backtopshow');
      }

      currentP = $(window).scrollTop();
    }
  }
});

module.exports = Common
