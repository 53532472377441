import './styles/application.sass';

import './js/promptForm';

import Login from './js/login';
import Common from './js/common';
import Contents from './js/contents';
//import sugar from 'sugar';
import ThreeSixty from './js/people-show';
import PeopleEdit from './js/people-edit';
import homeHomeroom from './js/home-homeroom';
import ahoy from './js/ahoy';
import Signature from './js/digitalSignature';
import filePreview from './js/filePreview';
import approvals from './js/approvals';

window.Login = Login;
window.Common = Common;
window.Contents = Contents;
window.ThreeSixty = ThreeSixty;
window.PeopleEdit = PeopleEdit;
window.homeHomeroom = homeHomeroom;
window.Signature = Signature;
window.filePreview = filePreview;
window.approvals = approvals;


