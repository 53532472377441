var Common = require('./common')
var Content = {}

Content.init = function(){
  if($.fn.colorPicker && $.fn.colorPicker.defaults){
    Common.colorPicker();
  }

  //TODO: Use rails validation  
  // $('.files_submit').click(function(){
  //   $('form#new_form').valid();
  // });

}

module.exports = Content
